import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/footer/Footer";
import Package from "./pages/Package";
import Contact from "./pages/Contact";
import Home from "./pages/Home";
import About from "./pages/About";
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import BlogList from '../src/Components/admin/BlogList';
import AdminBlogForm from '../src/Components/admin/AdminBlogForm';
import BlogPost from '../src/Components/BlogPost/BlogPost';

const App = () => {
  return (
    <BrowserRouter>
      <div>
        <Navbar />
       
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/package" element={<Package />} />
          <Route path="/Contact" element={<Contact />} />
          <Route path="/About" element={<About />} />
          <Route path="/admin" element={<AdminBlogForm />} />
          <Route path="/blog" element={<BlogList />} />
          <Route path="/blog/:id" element={<BlogPost />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
};

export default App;