import React, { useState } from 'react';
import '../bookingCalendar/BookingCalendar.css';
import Payment from '../Payment/Payment'; // Make sure this path is correct

const BookingCalendar = ({ packageData, onClose, onBook }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    contactNumber: '',
    startDate: '',
    numberOfPeople: ''
  });
  const [showPayment, setShowPayment] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowPayment(true);
  };

  const handlePaymentSuccess = () => {
    onBook(packageData, formData.startDate, formData);
    onClose();
  };

  const handlePaymentCancel = () => {
    setShowPayment(false);
  };

  const totalAmount = packageData.price * parseInt(formData.numberOfPeople || 1);

  return (
    <div className="booking-calendar">
      <div className="booking-calendar-content">
        <h2>Book {packageData.packageName}</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input 
              type="text" 
              id="name" 
              name="name" 
              value={formData.name} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input 
              type="email" 
              id="email" 
              name="email" 
              value={formData.email} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="contactNumber">Contact Number</label>
            <input 
              type="tel" 
              id="contactNumber" 
              name="contactNumber" 
              value={formData.contactNumber} 
              onChange={handleChange} 
              required 
            />
          </div>
          <div className="form-group">
            <label htmlFor="numberOfPeople">Number of People</label>
            <select
              id="numberOfPeople"
              name="numberOfPeople"
              value={formData.numberOfPeople}
              onChange={handleChange}
              required
            >
              <option value="">Select number of people</option>
              {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14 ,15 ,16 ,17 ,18, 19, 20, 21, 22, 23, 24, 25].map((num) => (
                <option key={num} value={num}>{num}</option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="startDate">Start Date</label>
            <input 
              type="date" 
              id="startDate" 
              name="startDate" 
              value={formData.startDate} 
              onChange={handleChange} 
              required 
            />
          </div>
          <button type="submit" className="submit-button">Proceed</button>
          <button type="button" className="close-button" onClick={onClose}>Cancel</button>
        </form>
      </div>
      {showPayment && (
        <Payment
          amount={totalAmount}
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentCancel={handlePaymentCancel}
          bookingDetails={{
            packageData: packageData,
            date: formData.startDate,
            formData: {
              name: formData.name,
              email: formData.email,
              contactNumber: formData.contactNumber
            },
            numberOfPeople: parseInt(formData.numberOfPeople || 1)
          }}
        />
      )}
    </div>
  );
};

export default BookingCalendar;