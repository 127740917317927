import React, { useRef } from "react";
import {OrbitControls, Stars} from "@react-three/drei"
import {useLoader, useFrame} from "@react-three/fiber";
import EarthDayMap from "../../../src/assets/8k_earth_daymap.jpg"
import EarthCloudsMap from "../../../src/assets/8k_earth_clouds.jpg"
import EarthSpecularMap from "../../../src/assets/8k_earth_specular_map.jpg"
import EarthNormalMap from "../../../src/assets/8k_earth_normal_map.jpg"
import {TextureLoader} from "three";
import * as THREE from "three";


export function Earth(props){

const [colorMap, normalMap,specularMap,cloudsMap]=useLoader(TextureLoader, 
    [EarthDayMap,  EarthNormalMap,EarthSpecularMap,EarthCloudsMap]
  );
    const earthRef=useRef();
    const cloudsRef=useRef();
    useFrame(({clock})=>{
        const elapsedTime=clock.getElapsedTime();
        earthRef.current.rotation.y=elapsedTime/6;
        cloudsRef.current.rotation.y=elapsedTime/6; //y position axis ma rotation
    });
    return( 
    <>
    <ambientLight intensity={1}/>
    <pointLight color="#f6f3ae" 
    position={[2, 0, 2]} 
    intensity={1.2}/>
     {/* <Stars 
    radius={300} 
    depth={60}
     count={20000} 
     factor={7} 
     saturation={0} 
     fade={true}/> */}

    <mesh ref={cloudsRef}> 
    <sphereGeometry args={[3.04, 32, 32]} />
    <pointLight color="#f6f3ae" position={[2, 0, 2]} intensity={1.2}/>
   
    <meshPhongMaterial 
    map={cloudsMap} 
    opacity={0.4} 
    depthWrite={true}
    transparent={true}
    side={THREE.DoubleSide}
    />
    </mesh>
    <mesh ref={earthRef}>
    <sphereGeometry args={[3, 32, 32]} />
    <meshPhongMaterial specularMap={specularMap}/>
    <meshStandardMaterial map={colorMap} normalMap={normalMap} metalness={0.4}/>
    <OrbitControls 
    enableZoom={true}
     enablePan={true} 
     enableRotate={true} 
     zoomSpeed={0.6}
      panSpeed={0.5}
        rotateSpeed={0.4}
       />
    </mesh>
    </>
    );
}