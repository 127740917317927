import React, { useEffect, useState } from "react";
import Background from '../Components/Background/background';
import Hero from "../Components/Hero/Hero";
import TrekkingPackage from "../Components/package/TrekkingPackage";
import ChatBot from '../Components/ChatBot/ChatBot';



const Home = () => {
  let heroData = [
    {text1:"Dive into",text2:"what you love"},
    {text1:"Explore the",text2:"unknown trails"},
    {text1:"Give in to",text2:"your passion"},
  ];

  const [heroCount, setHeroCount] = useState(2);
  const [playStatus, setPlayStatus] = useState(true);
  
  useEffect(() => {
    const interval = setInterval(() => {
      setHeroCount((count) => (count === 2 ? 0 : count + 1));
    }, 3000);
    return () => clearInterval(interval);
  }, []);

  return (
    <div>
      
      <Background playStatus={playStatus} heroCount={heroCount} />
      <Hero
        setPlayStatus={setPlayStatus}
        heroData={heroData[heroCount]}
        heroCount={heroCount}
        setHeroCount={setHeroCount}
        playStatus={playStatus}
      />
      <TrekkingPackage />
      <div className="chatbot-section">
        <ChatBot />
        
      </div>
    </div>
  );
};


export default Home;