import React from 'react';
import '../pages/pagesCss/About.css';
import ChatBot from '../Components/ChatBot/ChatBot';
import TravelHimalayaLogo from '../assets/travel-himalayan-logo.png'; // Ensure the path matches your project structure
import { Link } from 'react-router-dom';

const explanationFullText = `Lead us from the unreal to the real\nLead us from darkness to light\nLead us from death to immortality\nAum peace, peace, peace!\nThis ancient mantra embodies the essence of Mantra Mountain's philosophy. It is a prayer for spiritual transformation and enlightenment, guiding us to transcend illusions and falsehoods, to gain knowledge and clarity, and to overcome the fear of death, achieving eternal peace. Just as our treks lead you through the majestic Himalayas, this mantra leads you to inner peace and positive energy. As an official partner with Travel Himalaya Nepal, we ensure you receive the best travel experience possible.`;

const About = () => {
  return (
    <div className="about-container">
      <div className="about-content">
        <h1 className="about-title">About Mantra Mountain</h1>
        <div className="about-card">
          <div className="card-face card-front">
            <h2>Nepal's Premier Trekking Experience</h2>
            <p>Discover the magic of the Himalayas with Mantra Mountain, your gateway to unforgettable adventures in Nepal.</p>
          </div>
          <div className="card-face card-back">
            <h2>Why Choose Us?</h2>
            <ul>
              <li>Expert local guides</li>
              <li>Tailored trekking packages</li>
              <li>Sustainable tourism practices</li>
              <li>Safety-first approach</li>
            </ul>
          </div>
        </div>
        <div className="about-features">
          <div className="feature">
            <i className="fas fa-mountain"></i>
            <h3>Diverse Routes</h3>
            <p>From Everest Base Camp to hidden gems of the Annapurna region.</p>
          </div>
          <div className="feature">
            <i className="fas fa-users"></i>
            <h3>Expert Team</h3>
            <p>Knowledgeable guides with years of Himalayan experience.</p>
          </div>
          <div className="feature">
            <i className="fas fa-heart"></i>
            <h3>Personalized Care</h3>
            <p>Tailored services to meet your specific needs and preferences.</p>
          </div>
        </div>
        <div className="about-cta">
          <h2>Ready to Embark on Your Himalayan Journey?</h2>
          <Link to="/Package" className="cta-link">
          <button className="cta-button">Explore Our Packages</button>
          </Link>
        </div>
        <div className="about-mantra">
          <h2>Our Mantra</h2>
          <p>{explanationFullText}</p>
        </div>
        <div className="about-partner">
          <h2>Our Partner</h2>
          <img src={TravelHimalayaLogo} alt="Travel Himalaya Nepal Logo" className="partner-logo" />
          <p>We are proud to be an official partner with Travel Himalaya Nepal, a leading travel agency dedicated to providing exceptional travel experiences in the Himalayas.</p>
        </div>
        <ChatBot />
      </div>
    </div>
  );
};

export default About;
