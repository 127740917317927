import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import './styles.css';

const BlogList = () => {
    const [blogs, setBlogs] = useState([]);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    const baseUrl = process.env.NODE_ENV === 'development'
        ? 'http://localhost:5000/api/blogs'
        : 'https://nodejs-serverless-function-express-henna-theta.vercel.app/api/blogs';

    useEffect(() => {
        const fetchBlogs = async () => {
            try {
                const response = await axios.get(baseUrl);
                setBlogs(response.data);
                setError(null);
            } catch (error) {
                console.error('Failed to fetch blogs:', error);
                setError('Failed to fetch blogs. Please try again later.');
                setBlogs([]);
            } finally {
                setLoading(false);
            }
        };

        fetchBlogs();
    }, [baseUrl]);

    if (loading) return <div className="loading">Loading...</div>;
    if (error) return <div className="error-message">Error: {error}</div>;
    if (blogs.length === 0) return <div className="no-blogs">No blogs found. Please add some blogs.</div>;

    return (
        <div className="blog-container">
            <h1>Blog List</h1>
            <div className="blog-grid">
                {blogs.map((blog) => (
                    <Link to={`/blog/${blog._id}`} key={blog._id} className="blog-card">
                        <div className="blog-card-inner">
                            {/* Front side */}
                            <div className="blog-card-front">
                                {blog.image && <img src={blog.image} alt={blog.title} />}
                                <h2>{blog.title}</h2>
                            </div>

                            {/* Back side */}
                            <div className="blog-card-back">
                                <h3>Highlights</h3>
                                {/* Dynamically display the most used words */}
                                <p>{blog.highlights?.map((word, index) => (
                                    <span key={index} className="keyword">{word}</span>
                                ))}</p>
                            </div>
                        </div>
                    </Link>
                ))}
            </div>
        </div>
    );
};

export default BlogList;
