import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm'; 
import rehypeRaw from 'rehype-raw'; 
import '../BlogPost/BlogPost.css'; 

const BlogPost = () => {
    const { id } = useParams(); 
    const [blog, setBlog] = useState(null);
    const [headings, setHeadings] = useState([]); 
    const [error, setError] = useState(null);

    // Determine the base URL based on the environment
    const baseUrl = process.env.NODE_ENV === 'development' 
        ? `http://localhost:5000/api/blogs/${id}` 
        : `https://nodejs-serverless-function-express-henna-theta.vercel.app/api/blogs/${id}`;

    // Fetch the blog data when the component mounts
    useEffect(() => {
        const fetchBlog = async () => {
            try {
                const response = await axios.get(baseUrl);
                setBlog(response.data);

                // Generate Table of Contents (TOC) by parsing headings from blog content
                const tempDiv = document.createElement('div');
                tempDiv.innerHTML = response.data.content;
                const headingElements = tempDiv.querySelectorAll('h2');
                const tocItems = Array.from(headingElements).map(heading => heading.textContent);
                setHeadings(tocItems); 
            } catch (error) {
                console.error('Failed to fetch blog:', error);
                setError('Failed to fetch blog. Please try again later.');
            }
        };

        fetchBlog();
    }, [baseUrl]); // Add baseUrl as a dependency

    if (error) {
        return <div>Error: {error}</div>;
    }

    if (!blog) {
        return <div>Loading...</div>;
    }

    return (
           
        <div className="blog-container">
            <h1>{blog.title}</h1>

            {blog.image && (
                <img src={blog.image} alt={blog.title} className="blog-image" />
            )}

            {headings.length > 0 && (
                <div className="toc">
                    <h2>Table of Contents</h2>
                    <ul>
                        {headings.map((heading, index) => (
                            <li key={index}>
                                <a href={`#${heading}`}>{heading}</a>
                            </li>
                        ))}
                    </ul>
                </div>
            )}
            
            <div className="blog-content">
                <ReactMarkdown
                    children={blog.content}
                    remarkPlugins={[remarkGfm]} 
                    rehypePlugins={[rehypeRaw]}  
                    components={{
                        h2: ({ node, ...props }) => (
                            <h2 id={props.children}>{props.children}</h2>
                        ), 
                    }}
                />
            </div>
        </div>
    );
};

export default BlogPost;