import React, { useState, useEffect } from 'react';
import { packageData } from '../Components/PackageData/PackageData';
import '../pages/pagesCss/Package.css';
import BookingCalendar from '../Components/bookingCalendar/BookingCalendar';
import Payment from '../Components/Payment/Payment';
import '../Components/Payment/Payment.css';
import Confetti from 'react-dom-confetti';

const Package = () => {
  const [packages, setPackages] = useState([]);
  const [hoveredPackage, setHoveredPackage] = useState(null);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);
  const [showConfetti, setShowConfetti] = useState(false);

  useEffect(() => {
    setPackages(packageData);
  }, []);

  const handleMouseEnter = (id) => {
    setHoveredPackage(id);
  };

  const handleMouseLeave = () => {
    setHoveredPackage(null);
  };

  const handleBookNow = (pkg) => {
    setSelectedPackage(pkg);
  };

  const handleCloseBooking = () => {
    setSelectedPackage(null);
  };

  const handleBooking = (packageData, date, formData) => {
    setBookingDetails({ packageData, date, formData });
    setIsPaymentOpen(true);
  };

  const handlePaymentSuccess = async () => {
    const { packageData, date, formData } = bookingDetails;

    try {
      const response = await fetch("http://localhost:5000/api/book", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          packageData,
          date,
          formData
        })
      });

      const result = await response.json();

      if (result.success) {
        setShowConfetti(true);
        setTimeout(() => setShowConfetti(false), 3000);
      } else {
        alert('Booking request failed to send. Please try again.');
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred. Please try again.');
    }

    setSelectedPackage(null);
    setIsPaymentOpen(false);
  };

  const handlePaymentCancel = () => {
    setIsPaymentOpen(false);
    setSelectedPackage(null);
  };

  const confettiConfig = {
    angle: 90,
    spread: 360,
    startVelocity: 40,
    elementCount: 70,
    dragFriction: 0.12,
    duration: 3000,
    stagger: 3,
    width: "10px",
    height: "10px",
    colors: ["#a864fd", "#29cdff", "#78ff44", "#ff718d", "#fdff6a"]
  };

  return (
    <div className="package-page">
      <h2 className="text-center">All Packages</h2>
      {showConfetti && <Confetti active={showConfetti} config={confettiConfig} />}
      <div className="package-grid">
        {packages.map((pkg) => (
          <div
            key={pkg.id}
            className={`package-card ${hoveredPackage === pkg.id ? 'hovered' : ''}`}
            onMouseEnter={() => handleMouseEnter(pkg.id)}
            onMouseLeave={handleMouseLeave}
          >
            <div className="package-card-inner">
              <div className="package-front">
                <div className="package-image-container">
                  <img src={pkg.imageUrl} alt={pkg.packageName} className="package-image" />
                  <div className="package-overlay">
                    <span className="difficulty-badge">{pkg.difficulty}</span>
                  </div>
                </div>
                <div className="package-details">
                  <h2>{pkg.packageName}</h2>
                  <p className="description">{pkg.description}</p>
                  <div className="package-info">
                    <span className="duration"><i className="fas fa-clock"></i> {pkg.duration} Days</span>
                    <span className="elevation"><i className="fas fa-mountain"></i> {pkg.maxElevation}</span>
                  </div>
                  <h3 className="price">${pkg.price}</h3>
                </div>
              </div>
              <div className="package-back">
                <h3>Highlights:</h3>
                <ul>
                  {pkg.highlights.map((highlight, index) => (
                    <li key={index}>{highlight}</li>
                  ))}
                </ul>
                <button className="book-now" onClick={() => handleBookNow(pkg)}>Book Now</button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedPackage && (
        <BookingCalendar 
          packageData={selectedPackage} 
          onClose={handleCloseBooking}
          onBook={handleBooking}
        />
      )}

      {isPaymentOpen && (
        <Payment 
          amount={bookingDetails.packageData.price * 0.25} 
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentCancel={handlePaymentCancel}
        />
      )}
    </div>
  );
};

export default Package;
