import React, { useState, useEffect } from 'react';
import { Navigation, EffectCube, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/effect-cube';
import 'swiper/css/pagination';
import BookingCalendar from '../bookingCalendar/BookingCalendar';
import Payment from '../Payment/Payment';
import { packageData } from '../PackageData/PackageData';


const TrekkingPackage = () => {
  const [packages, setPackages] = useState([]);
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [isPaymentOpen, setIsPaymentOpen] = useState(false);
  const [bookingDetails, setBookingDetails] = useState(null);

  useEffect(() => {
    setPackages(packageData);
  }, []);

  const handleBookNow = (packageData) => {
    setSelectedPackage(packageData);
  };

  const handleCloseBooking = () => {
    setSelectedPackage(null);
  };

  const handleBooking = (packageData, date, formData) => {
    setBookingDetails({ packageData, date, formData });
    setIsPaymentOpen(true);
  };

  const handlePaymentSuccess = async () => {
    // Logic for successful payment can be added here
    const { packageData, date, formData } = bookingDetails;

    const bookingDetailsObj = {
      packageName: packageData.packageName,
      date,
      name: formData.name,
      email: formData.email,
      persons: formData.persons,
      price: packageData.price
    };

    const formDataObj = new FormData();
    formDataObj.append("access_key", "0e1c0c9c-4d97-4e56-9f7f-547a2f4b3c7a");
    formDataObj.append("packageName", bookingDetailsObj.packageName);
    formDataObj.append("date", bookingDetailsObj.date);
    formDataObj.append("name", bookingDetailsObj.name);
    formDataObj.append("email", bookingDetailsObj.email);
    formDataObj.append("persons", bookingDetailsObj.persons);
    formDataObj.append("price", bookingDetailsObj.price);

    try {
      const res = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        body: formDataObj
      }).then((res) => res.json());

      if (res.success) {
        alert('Booking request sent successfully!');
      } else {
        alert('Booking request failed to send. Please try again.');
      }
    } catch (error) {
      alert('An error occurred. Please try again.');
    }

    setSelectedPackage(null);
    setIsPaymentOpen(false);
  };

  const handlePaymentCancel = () => {
    setIsPaymentOpen(false);
    setSelectedPackage(null);
  };

  return (
    <section id="packages">
      {selectedPackage && (
        <BookingCalendar 
          packageData={selectedPackage} 
          onClose={handleCloseBooking}
          onBook={handleBooking}
        />
      )}
      {isPaymentOpen && (
        <Payment 
          amount={bookingDetails.packageData.price * 0.25} 
          onPaymentSuccess={handlePaymentSuccess}
          onPaymentCancel={handlePaymentCancel}
        />
      )}
      <div className="container">
        <h3 className="text-center section-subheading">- Unforgettable Adventures Await -</h3>
        <h1 className="text-center section-heading">Discover Nepal's Majestic Himalayas</h1>
      </div>
      <div className="container">
        <Swiper
          modules={[Navigation, EffectCube, Pagination]}
          effect={'cube'}
          cubeEffect={{
            shadow: true,
            slideShadows: true,
            shadowOffset: 20,
            shadowScale: 0.94,
          }}
          slidesPerView={1}
          navigation={true}
          pagination={{ clickable: true }}
          loop={true}
          className="mySwiper"
        >
          {packages.map((packageData) => (
            <SwiperSlide key={packageData.id}>
              <div className="package-card">
                <div className="package-image">
                  <img src={packageData.imageUrl} alt={packageData.packageName} />
                  <div className="package-overlay">
                    <span className="difficulty-badge">{packageData.difficulty}</span>
                  </div>
                </div>
                <div className="package-card-content">
                  <h2>{packageData.packageName}</h2>
                  <p className="description">{packageData.description}</p>
                  <div className="package-details">
                    <span className="duration"><i className="fas fa-clock"></i> {packageData.duration} Days</span>
                    <span className="elevation"><i className="fas fa-mountain"></i> {packageData.maxElevation}</span>
                  </div>
                  <div className="highlights">
                    <h4>Highlights:</h4>
                    <ul>
                      {packageData.highlights.map((highlight, index) => (
                        <li key={index}>{highlight}</li>
                      ))}
                    </ul>
                  </div>
                  <div className="package-footer">
                    <h3 className="price">${packageData.price}</h3>
                    <button className="book-now" onClick={() => handleBookNow(packageData)}>Book Now</button>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>
  );
};

export default TrekkingPackage;